<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Statistiche appuntamenti</h4>
                </div>
                <DataTable :value='statRows'
                           class='p-datatable-gridlines'  dataKey='id'
                           :rowHover='true'
                           :rowClass="({ totale }) => totale  ? 'summary': ''"
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            :hideNew='true'
                            v-model="filters['global'].value"
                            :hide-new='true'
                            @refresh='refreshList()'
                            :loading='loading'
                        >
                            <template #after-left>
                                <Calendar
                                    :showTime="true"
                                    inputId="from_date"
                                    v-model="fromDate"
                                    dateFormat="dd/mm/yy"
                                    class='mb-2 ml-2'
                                    placeholder='Dalla data'
                                />
                                <Calendar
                                    :showTime="true"
                                    inputId="to_date"
                                    v-model="toDate"
                                    dateFormat="dd/mm/yy"
                                    class='mb-2 ml-2'
                                    placeholder='Alla data'
                                />
                            </template>
                        </ListHeader>
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='name' header='Nome' :sortable='true' style='min-width:12rem' />
                    <Column field='email' header='Email' :sortable='true' style='min-width:12rem' />
                    <Column field='free' header='App FREE' :sortable='true' style='min-width:12rem' />
                    <Column field='paid' header='App PAID' :sortable='true' style='min-width:12rem' />

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Psycho/Patients');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
            fromDate: null,
            toDate: null,
        };
    },
    computed: {
        ...mapGetters(['stats', 'loading']),
        statRows(){
            if (!this.stats){
                return [];
            }
            const arr = [];
            let free = 0;
            let paid = 0;
            this.stats.forEach(row => {
                const r = {
                    free: row['free'],
                    paid: row['paid'],
                };
                free += r.free;
                paid += r.paid;
                if (row['psycho']){
                    r['name'] = row['psycho']['name'];
                    r['email'] = row['psycho']['email'];
                }
                arr.push(r);
            })
            arr.push({
                free: free,
                paid: paid,
                totale: true,
                name: 'Totale'
            })
            return arr;
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchStats']),
        refreshList() {
            this.fetchStats({
                fromDate: this.fromDate,
                toDate: this.toDate,

            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>

<style>
.p-datatable{
    .p-datatable-tbody{
        tr.summary {
            background-color: #d6ffd7;
            font-weight: 700;
        }
    }
}
</style>
